.example {
    &-container {
        text-align: center;
        min-height: 100vh;
        min-width: 1200px;
        height: 100vh;
        width: 100%;
        align-items: center;
        justify-content: center;
        background-color: #EEEEEE;
    }

    &-frame {
        position: absolute;
        overflow: auto;
        top: 80px;
        bottom: 80px;
        left: 20px;
        right: 20px;
        border-radius: 15px;
        box-shadow: 0 5px 25px 0 rgb(0 0 0 / 40%);
    }
}
