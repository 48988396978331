.account {
  &-detail-container {
    height: calc(100% - 25vh);
    overflow: auto;
  }

  &-balance {
    &-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      min-height: 100%;
      z-index: 0;

      &--left {
        width: 200px;
        background-color: white;
        min-width: 200px;
      }

      &--right {
        width: 100%;
        padding: 20px 40px;
        background-color: #f4f4f4;
      }
    }

    &-detail {
      width: 100%;
      padding: 20px;
      padding-top: 32px;
      background-color: #dddddd;
      text-align: left;
      height: 108px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      &-title {
        margin: 0;
        font-size: 28px;
      }

      &-description {
        margin: 0;
        font-size: 14px;
        margin-bottom: 10px;
        margin-left: 10px;
      }

      &-summary {
        display: flex;
        flex-direction: row;
        padding: 20px 0 10px 0;
        justify-content: space-between;
      }

      &-chart-card {
        font-size: 11px;
        height: 65% !important;

        &-title {
          margin: 0;
          margin-bottom: 4px;
          margin-left: 12px;
          font-weight: 600;
          font-size: 22px;
        }

        &-balance {
          font-size: 20px;
          font-weight: 800;
          margin-right: 20px;
          background-color: #e4f9d1;
          padding: 5px 30px;
          border-radius: 30px;
        }

        &-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }

      &-product {
        height: 90px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(70,123,67,0.1);
        border: 1px solid #477942;
        border-radius: 10px;
        padding: 32px;

        &-title {
          margin: 0;
          font-size: 24px;
          font-weight: 700;
          color:  #181918;
        }

        &-detail {
          margin: 0;
          font-size: 24px;
          font-weight: 300;
          color: #181918;
          margin-right: 4px;
        }

        &-action {
          border-radius: 15px;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          padding: 6px 12px;

          &--primary {
            background-color: #487A43;
            color: white;
            border: none;
            margin-right: 10px;
          }

          &--secondary {
            background-color: transparent;
            color: #487A43;
            border: 1px solid #487A43;
          }
        }

        &-container {
          &--vertical {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }

          &--horizontal {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }
      }

      &-history {
        display: flex;
        flex-direction: row;
        padding-bottom: 10px;
        justify-content: space-between;

        &-card {
          text-align: left;
          display: flex;
          flex-direction: row;
          border: 1px solid #CCCCCC !important;
          border-radius: 10px !important;
          margin-bottom: 20px !important;
          box-shadow: none !important;
          width: 49% !important;

          &-title {
            font-size: 22px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 20px;
          }

          &-action {
            font-size: 13px;
            font-weight: 600;
            text-decoration: underline;
            cursor: pointer;
            margin: 0;
          }

          &-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }

          &-item {
            background-color: #f4f4f4;
            padding: 12px 20px;
            border-radius: 10px;
            margin-bottom: 12px;

            &-row {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              p {
                margin: 0;
                font-size: 12px;
                font-weight: 400;
              }

              .item-row-title {
                font-size: 14px;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}
