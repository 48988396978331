.body-content-container {
    height: calc(100% - 25vh);
    display: flex;

    .container-left {
        width: 50%;
        max-height: 100%;
        background-color: #FEE9F8;
        color: #222222;
        font-weight: 500;
        font-size: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 7vh 0;
    }

    .container-right {
        width: 50%;
        height: 100%;
        background-color: #ffffff;
        align-items: center;
        display: flex;
    }
}

.radio-selector-label {
    background-color: white;
    border-radius: 20px;
    border: 0.5px solid #6c757d;
    padding-left: 10px;
    padding-right: 20px;
    margin-right: 20px;
    font-size: 13px;
    font-weight: 500;
}

.actionable-button {
    min-width: 108px;
    height: 108px;
    border-radius: 50%;
    background-color: #4FB4D1;
    border: 2px solid white;
    color: white;
    cursor: pointer;
    position: absolute;
    top: 25%;
    left: calc(50% - 54px);
    font-size: 32px;
    line-height: 1;
    text-align: center;

    &--disabled {
        background-color: #C5C5C5;
        border: 2px solid #6c757d;

        &:hover {
            background-color: #C5C5C5;
            border: 2px solid #6c757d;
        }
    }
}

.loading-decision-icon,
.remove-decision-icon {
    margin-top: 5px;
}

.ice-cream-order {
    &-container {
        margin: 0 auto;
        display: block;
    
        .order-cart-container {
            margin-top: 24px;
        }
    
        .order-title {
            font-size: 12px;
            margin-bottom: 6px;
        }
    }

    &-selector {
        margin-left: 15px;
    }
}

.button-icon {
    margin-right: 6px;
}
