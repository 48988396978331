.balance-detail-card {
    text-align: left;
    display: flex;
    flex-direction: row;
    border: 1px solid  #CCCCCC !important;
    border-radius: 10px !important;
    margin-bottom: 10px !important;
    box-shadow: none !important;
    width: 32% !important;

    &-data {
        margin:0;
        font-size: 24px;
        font-weight: 800;
        margin: 0;

        &-container {
            padding: 5px 20px;
            border-radius: 30px;
            margin-top: 12px;

            &--grey {
                background-color: rgba(73,125,68,0.05);
            }

            &--yellow {
                background-color: rgba(211,100,7,0.05);
            }
        }
    }

    &-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &-info {
        font-size: 13px;
        color:#888888;
        margin: 0;
    }

    &-title {
        font-size: 22px;
        font-weight: 600;
        margin: 0;
    }

    &--clickable {
        cursor: pointer;
        background-color: #e4f9d1 !important;
    }

    .card-detail-icon {
        font-size: 32px;
        margin-right: 10px;
    }
}