.use-case {
    &-container {
        background-color: #E4F9D1;
        border: 1px solid #AFFC6D;
        border-radius: 10px;
        margin-right: 25px;
        padding: 30px;
        width: 370px;
        cursor: pointer;
    }

    &-pill {
        border-radius: 20px;
        padding: 3px 35px;
        font-size: 13px;
        margin: 0;
        width: max-content;

        &--grey {
            background-color: #EEEEEE;
            border: 1px solid #979797;
        }

        &--blue {
            background-color: rgba(38,58,121,0.1);
            border: 1px solid #263A79;
        }
    }

    &-image-container {
        width: 100%;
        margin-top: 22px;
        border-radius: 10px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 170px;
    }

    &-text {
        color: #181918;
        margin: 0;

        &--bold {
            font-size: 17px;
            font-weight: bold;
            margin-top: 20px;
        }

        &--regular {
            font-size: 14px;
            font-weight: 300;
            margin-top: 8px;
            line-height: 20px;
        }
    }
}