.account-menu {
    &-info {
        width: 100%;
        padding: 20px;
        background-color: #e4f9d1;
        text-align: left;
    }

    &-info-row{
        display: flex;
        font-size: 12px;
        align-items: center;
  
        &-text {
            margin: 0;
            padding-right: 8px;
        }
  
        &-title {
          font-size: 28px;
          font-weight: 600;
          margin: 0;
          padding-right: 8px;
      }
    }
  
    &-options {
      &-container {
        color: #999999;
        font-size: 12px;
        text-align: left;
  
        p {
            margin: 0;
            padding-left: 20px;
            font-weight: 300;
            font-size: 18px;
        }
      }
  
      &-item {
          height: 60px;
          border-bottom: 1px solid rgba(216,216,216,0.3);
          display: flex;
          align-items: center;
  
          &-row {
              display: flex;
              justify-content: space-between;
              font-size: 10px;
              
              p {
                  margin: 0 20px 8px 10px;
                  font-weight: 300;
              }
          }

          &-highlight {
              width: 11px;
              height: 100%;
              background-color: #624029;
          }

          &-selectable {
            width: 11px;
            height: 100%;
            background-color: white;
          }
  
          &--selected {
              background-color: rgba(210,104,9,0.1);
              color: black;
  
              p { 
                  font-weight: 500;
              }
          }
      }
  
      &-subitem {
          margin-left: 25px;
          text-align: end;
          padding-top: 20px;
          font-size: 12px;
      
          p {
              margin: 0 20px 8px 10px;
              font-weight: 400;
          }
      }

      &-settings {
        margin-left: 25px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: end;
        padding-top: 20px;
        font-size: 12px;

        &-title {
            font-size: 14px;
            margin-right: 10px;
            margin-bottom: 16px;
        }

        &-icon {
            font-size: 14px;
            margin-right: 20px;
            margin-top: 2px;
        }

        div {
            display: flex;
        }

        p {
            padding: 2px 16px 0 0;
            font-size: 12px;
            font-weight: 300;
        }

      }
    }
}