.influencer-item {
    &-name {
        font-size: 18px;
        margin-bottom: 10px;
    }

    &-selector-label {
        color: #181918;
        font-size: 12px;
        font-weight: 600;
        padding: 0 16px !important;
        border: none !important;
        background-color: transparent !important;
        opacity: 0.5;

        &--selected {
            opacity: 1;
            border: 3px solid #44E0EE !important;
            background-color: white !important;
        }

        &-container {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &-indicator {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            margin-right: 6px;

            &--blue {
                background-color: #263A79;
            }
    
            &--pink {
                background-color: #FD7AB8;
            }
    
            &--black {
                background-color: #000000;
            }
    
            &--green {
                background-color: #AFFC6D;
            }
    
            &--red {
                background-color: #F73939
            }
        }
    }
}

.bootstrap-switch-handle {
    &-on {
        background-color: white;
        border-bottom-left-radius: 20px !important;
        border-top-left-radius: 20px !important;
        color: black !important;
        border-radius: 20px;
    }

    &-off {
        background-color: white;
        border-radius: 20px;
        color: black !important;
    }
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
    background-color: #444444 !important;
    width: 26px !important;
    height: 26px !important;
    top: -2px !important;
    border: 1px solid white;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label {
    background-color:#4FB4D1 !important;
    width: 30px !important;
    height: 30px !important;
    top: -4px !important;
    border: 1px solid white;
}
