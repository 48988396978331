.examples-app {
  &-container {
    height: 100vh;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 80px 142px 30px;
  }

  &-text {
    &-container {
      padding-bottom: 30px;
    }

    &--bold {
      font-size: 40px;
      font-weight: bold;
      line-height: 38px;
    }

    &--regular {
      font-size: 17px;
      font-weight: 300;
      line-height: 29px;
    }
  }

  &-cards {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
