.rounded-selector {
    &-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        border-radius: 20px;
        border: 1px solid black;
        margin-top: 6px;
    }

    &-item {
        padding: 0 40px;
        cursor: not-allowed;

        &-text {
            font-size: 14px;
            font-weight: bold;
            margin: 0;
            padding: 6px 0;
        }

        &--selected {
            background-color: #AFFC6D;
            border-left: 1px solid black;
            border-right: 1px solid black;
            cursor: default;
        }
    }
}