.bank-loan {
    &-container {
        height: calc(100% - 25vh);
        overflow: auto;
    }

    &-account {
        &-container {
            display: flex;
            flex-direction: row;
            width: 100%;
            z-index: 0;
            min-height: 100%;
       
            &--left {
                width: 200px;
                background-color: white;
                min-width: 200px;
                border-right: 1px solid #F4F4F4;
            }
       
            &--right {
               width: 100%;
               padding: 0 34px;
               background-color: white;
           }
         }
    }

    &-header {
        width: 100%;
        text-align: left;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e4f9d1;;
        border-bottom: 6px solid #e4f9d1;
    
        &-text {
            margin: 0;
            font-size: 18px;
            font-weight: 600;
        }
    }

    &-offer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 14px;
        margin: 20px 0;

        &-text {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
        }

        &-amount {
            font-weight: 300;
            font-size: 20px;
            background-color: #F5F5F5;
            padding: 10px 30px;
            border-radius: 30px;
            margin: 0;

            strong {
                font-weight: 800;
            }
        }
    }

    &-loader-container {
        margin-top: 15%;
    }

    &-cards-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items:flex-start;

        &-loader {
            font-size: 14px;
            margin: auto;

            i {
                margin-right: 10px;
            }
        }
    }

    &-account-info-card {
        border-radius: 10px;
        background-color: #F5F5F5;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 20px 35px;

        &-container {
            margin: 10px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items:flex-start;
            height: 100px;
        }

        &-info {
            font-size: 12px;
            color:#888888;
            margin: 0;
        }
    
        &-title {
            text-align: left;
            font-size: 18px;
            font-weight: 600;
            margin: 0;
        }

        &-data {
            font-size: 32px;
            font-weight: 800;
            margin: 0;
        }
    }
}