.body-content-footer {
    height: 25vh;
    display: flex;
    background-color: rgb(39, 40, 34);;

    .code-container {
        width: 50%;
        max-height: 100%;
        padding: 10px 20px;
        text-align: left;
        overflow: scroll;
        border: 0.5px dashed #e4f9d1;

        .code-title {
            color: white;
            font-size: 13px;

            &-container {
                display: flex;
                flex-direction: row;
            }

            &-bold {
                font-size: 13px;
                color: black;
                padding: 0 8px;
                border-radius: 20px;
                margin-right: 8px;

                &--green {
                    background-color: #e4f9d1;
                }

                &--yellow {
                    background-color: rgb(252, 241, 217);
                }
            }
        }

        .code-comment {
            color: white;
            font-size: 11px;
        }
    }
}
