.gallery-navigation {
    &-container {
        height: 60px;
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: white;
        width: 100%;
        z-index: 2;
        left: 0px;
        overflow: hidden;
        border-bottom: 1px solid #CCCCCC;

        &--top {
            top: 0px;
        }

        &--bottom {
            bottom: 0px;
        }
    }

    &-logo {
        width: 235px;
        padding: 0 30px;
        height: 100%;
        margin-right: 6px;

        &-block {
            border-right: 1px solid #CCCCCC;
        }
    }

    &-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-right: 20px;
    }

    &-button-container {
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 20px;
        padding-left: 12px;
    }

    &-text {
        font-size: 14px;
        font-weight: bold;
        color: #263A79;
        margin: 0;
        cursor: pointer;
    }

    &-icon {
        font-size: 11px;
        background-color: #263A79;;
        margin: 0;
        margin-right: 6px;
        padding: 3px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: white;
        font-weight: bold;
        cursor: pointer;
    }

    &-secondary-button {
        font-size: 12px;
        padding: 3px 12px;
        font-weight: 500;
        font-style: italic;
        line-height: normal;
        cursor: pointer;
        color: #263A79;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0;
        border: 1px solid #263A79;
        background-color: #e4f9d1;
        border-radius: 20px;

        &:hover {
            border: 1.5px solid #263A79;
        }
    }

    &-tertiary {
        &-container {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &-action-button {
            font-size: 14px;
            padding: 12px 20px;
            font-weight: bold;
            cursor: pointer;
            line-height: normal;
            color: white;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
            background-color:#263A79;
            border-radius: 30px;
            border: none;
        }

        &-text {
            font-size: 14px;
            margin: 0;
            margin-right: 10px;
            color: #181918;
        }
    }
}
