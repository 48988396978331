.bank-header {
    display: flex;
    align-items: center;
    min-height: 50px;
    height: auto;
    font-size: 11px;
    min-width: 200px;
    margin: 0;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-bottom: 1px solid rgba(216,216,216,0.3);

    &-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &-title {
        margin: 0;
        padding-left: 10px;
        font-weight: 900;
        line-height: 1;
    }

    &-icon {
        width: 22px;
        height: 22px;
    }

    &-account-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 14px;
        min-height: 100%;
    }

    &-message-container {
        background-color: #D26507;
        color: white;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &-message-text {
        margin: 0;
        font-weight: 400;
        font-size: 19px;

        &--highlighted {
            margin: 0;
            font-size: 19px;
            font-weight: 800;
            margin-right: 4px;
        }
    }
}

.account {
    &-navbar {
        width: 100%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        display: flex;
        padding: 0 20px;
    
        &-items {
            margin: 0;
            display: flex;
            height: 100%;
            align-items: center;
            font-size: 12px;
        }
    
        &-text-item {
            margin: 0 8px;
        }
    
        &-menu-item {
            color: #181918;
            margin: 0 8px;
        }
    
        &-icon-item {
            margin-left: 2px;
            font-size: 20px !important;
        }
    }
}