.loan-offer-card {
    margin-bottom: 10px;
    border: 1px solid #CCCCCC;
    border-radius: 10px;
    width: 100%;
    padding: 15px 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &-title {
            font-size: 30px;
            font-weight: 800;
            margin: 0;
        }

        &-text {
            font-size: 16px;
            font-weight: 300;
            margin: 0;
            padding-bottom: 8px;

            strong {
                font-weight: 600;
            }
        }
    }

    &-button {
        background-color: #457C43;
        border-radius: 30px;
        padding: 8px 35px;
        font-size: 17px;
        font-weight: 700;
        border: none;
        color: white;
        cursor: pointer;
        margin-bottom: 3px;
    }
}
